const windows = [
   {
      n: "window-1",
      clip: "M166.422,79.837L98.47,49.663L33.578,79.837L33.578,262.755L166.422,262.755L166.422,79.837Z",
      d: [
         "M26.177,271.358L173.823,271.358",
         "M176.925,76.191L98.228,41.15L23.075,76.191",
         "M33.578,120.925L166.422,120.925",
         "M124.893,262.755L124.893,61.396",
      ],
      renard: { x: 68, y: 135 },
   },
   {
      n: "window-2",
      clip: "M164.926,61.928L95.231,18.514L28.674,61.928L28.674,283.903L164.926,283.903L164.926,61.928Z",
      d: [
         "M20.276,293.486L179.724,293.486",
         "M164.926,80.609L28.674,80.609",
         "M95.231,18.514L52.733,80.609",
         "M95.231,18.514L137.59,80.609",
         "M95.231,18.514L95.231,62.214L85.692,80.609",
         "M95.231,62.214L104.769,80.609",
         "M67.613,80.609L67.613,283.903",
         "M122.848,80.609L122.848,283.903",
         "M164.926,160.33L28.674,160.33",
      ],
      renard: { x: 75, y: 162 },
   },
   {
      n: "window-3",
      clip: "M174.732,103.654C174.732,83.834 166.858,64.826 152.844,50.811C138.829,36.796 119.82,28.923 100,28.923L100,28.923C80.18,28.923 61.171,36.796 47.156,50.811C33.142,64.826 25.268,83.834 25.268,103.654L25.268,275.19L174.732,275.19L174.732,103.654Z",
      d: [
         "M100,109.486C100,99.576 96.063,90.072 89.056,83.064C82.048,76.057 72.544,72.12 62.634,72.12L62.634,72.12C52.724,72.12 43.22,76.057 36.212,83.064C29.205,90.072 25.268,99.576 25.268,109.486L25.268,275.19L100,275.19L100,109.486Z",
         "M174.732,109.486C174.732,99.576 170.795,90.072 163.788,83.064C156.78,76.057 147.276,72.12 137.366,72.12L137.366,72.12C127.456,72.12 117.952,76.057 110.944,83.064C103.937,90.072 100,99.576 100,109.486L100,275.19L174.732,275.19L174.732,109.486Z",
         "M100,29.023C115.174,29.023 127.494,41.242 127.494,56.417C127.494,71.591 115.174,83.91 100,83.91C84.826,83.91 72.506,71.591 72.506,56.417C72.506,41.242 84.826,29.023 100,29.023Z",
         "M25.268,152.057L174.732,152.057",
         "M15.97,283.077L184.03,283.077",
      ],
      renard: { x: 38, y: 155 },
   },
   {
      n: "window-4",
      clip: "M30.054,63.732C49.979,50.123 74.067,42.16 100,42.16C125.933,42.16 150.021,50.123 169.946,63.732L169.946,269.069L30.054,269.069L30.054,63.732Z",
      d: [
         "M16.877,58.097C40.556,41.088 69.181,31.136 100,31.136C130.819,31.136 159.444,41.088 183.123,58.097",
         "M58.675,49.209L100,90.966L141.325,49.209",
         "M30.054,90.966L169.946,90.966",
         "M100,269.069L100,90.966",
         "M16.877,280.864L183.123,280.864",
      ],
      renard: { x: 78, y: 143 },
   },
   {
      n: "window-5",
      clip: "M168.473,43.928L31.427,43.928L31.427,267.431L168.573,267.431L168.473,43.928Z",
      d: [
         "M85.189,43.928L85.189,267.431",
         "M31.427,122.501L168.573,122.501",
         "M22.616,30.477L177.384,30.477",
         "M22.616,281.523L177.384,281.523",
         "M27.423,37.296L172.577,37.296",
         "M27.423,274.704L172.577,274.704",
      ],
      renard: { x: 97, y: 150 },
   },
   {
      n: "window-6",
      clip: "M172.121,54.614L27.779,54.614L27.779,286.088L172.221,286.088L172.121,54.614Z",
      d: [
         "M27.779,39.303C48.515,25.078 73.336,16.797 100,16.797C126.664,16.797 151.485,25.078 172.221,39.303L27.779,39.303Z",
         "M19.143,46.806L180.857,46.806",
         "M19.143,295.203L180.857,295.203",
         "M100,54.614L100,286.088",
         "M27.779,145.716L172.221,145.716",
      ],
      renard: { x: 36, y: 160 },
   },
   {
      n: "window-7",
      clip: "M174.732,107.43C174.732,87.61 166.858,68.602 152.844,54.587C138.829,40.572 119.82,32.699 100,32.699L100,32.699C80.18,32.699 61.171,40.572 47.156,54.587C33.142,68.602 25.268,87.61 25.268,107.43L25.268,278.966L174.732,278.966L174.732,107.43Z",
      d: [
         "M179.973,80.128C175.991,67.737 168.905,56.337 159.178,47C143.483,31.935 122.196,23.471 100,23.471L100,23.471C77.804,23.471 56.517,31.935 40.822,47C31.095,56.337 24.009,67.737 20.027,80.128",
         "M17.937,288.529L182.063,288.529",
         "M25.268,120.596L174.732,120.596",
         "M100,120.596L31.143,78.387",
         "M100,120.596L168.857,78.387",
         "M100,120.596L70.833,38.625",
         "M100,119.372L129.167,37.402",
         "M100,120.596L100,278.966",
      ],
      renard: { x: 80, y: 155 },
   },
   {
      n: "window-8",
      clip: "M167.373,54.762L32.527,54.762L32.527,286.364L167.473,286.364L167.373,54.762Z",
      d: [
         "M100,14.607L179.378,40.241L20.622,40.241L100,14.607Z",
         "M20.622,297.393L179.378,297.393",
         "M26.775,47.222L173.225,47.222",
         "M100,54.762L100,286.364",
         "M167.473,126.556L32.527,126.556",
         "M167.473,205.845L32.527,205.845",
      ],
      renard: { x: 40, y: 160 },
   },
   {
      n: "window-9",
      clip: "M164.987,50.121L34.913,50.121L34.913,269.199L165.087,269.199L164.987,50.121Z",
      d: [
         "M34.913,287.654L165.087,287.654",
         "M23.28,278.302L176.72,278.302",
         "M180.929,50.121C153.916,50.121 136.148,24.346 100,24.346C63.852,24.346 46.084,50.121 19.071,50.121C-7.942,50.121 207.942,50.121 180.929,50.121Z",
         "M100,50.121L100,269.199",
         "M34.913,131.237L165.087,131.237",
      ],
      renard: { x: 54, y: 145 },
   },
];

const Renard = ({ x, y, id, clip }) => {
   return (
      <>
         <clipPath id={id}>
            <path d={clip} />
         </clipPath>

         <g clipPath={`url(#${id})`}>
            <g transform={`translate(${x},${y})`}>
               <path
                  fill="#F9642D"
                  fillRule="nonzero"
                  d="M75.803 126.956c-.086-3.925.007-24.202-2.219-36-1.414-7.494-2.18-15.081-3.62-22.573-1.144-5.956-3.074-12.164-2.785-18.292.162-3.434 1.061-6.751 2.078-10.016 1.72-5.524 3.228-11.185 5.455-16.536 1.8-4.326 4.19-8.353 6.231-12.562 1.021-2.104 2.063-4.297 2.486-6.619.549-3.017-1.243-3.448-3.231-1.594a52.522 52.522 0 00-2.863 2.898 116.18 116.18 0 00-13.752 20.905c-1.858 3.631-3.13 8.28-5.679 11.52-1.156 1.47-6.253 1.176-7.778 1.23-.617.022-1.256-.136-1.684-.601-1.737-1.887-2.735-6.366-3.526-8.425-1.626-4.447-3.17-8.929-4.944-13.321-1.026-2.542-2.064-5.092-3.232-7.573-.565-1.202-1.131-2.407-1.758-3.578-.804-1.502-1.603-2.981-2.785-4.214-.642-.67-2.114-1.156-2.461.143-.147.553-.27 1.122-.277 1.694a26.509 26.509 0 00.211 3.704c.194 1.506.53 2.991.841 4.478 1.632 7.812 3.882 15.409 5.146 23.31.7 4.371 1.547 9.612-.14 13.854-1.507 3.794-5.095 6.505-8.109 9.047-5.514 4.652-10.953 9.371-16.789 13.619-.825.601-1.654 1.195-2.479 1.796-2.017 1.468-4.134 2.909-5.856 4.736-.518.549-1.613 1.632-1.339 2.544.218.727 1.554.844 2.138.872 2.596.123 5.311-.784 7.758-1.521 6.86-2.066 13.814-3.884 20.648-6.027 2.141-.672 6.491-2.281 8.619-.648 1.761 1.351.483 5.298.322 7.062-1.477 16.097-5.404 38.948-6.466 46.537 5.916.561 16.175.069 22.127-.072 6.576-.156 13.139.537 19.712.223z"
               />
               <path d="M44.116 62.159c1.244-.821 1.968-2.009 1.693-3.525a6.679 6.679 0 00-.359-1.275l-.031-.082c-.179-.355-.142-.372-.402-.646a4.373 4.373 0 00-.706-.632c-1.053-.737-2.277-.213-3.198.466-.192.142-.416.319-.569.521-.975 1.291-.883 3.264.262 4.441.368.378.865.663 1.381.776l.275.036.169.017.328-.006c.342-.006.333-.032.662-.164l.495.073z" />
               <path d="M30.958 55.093a5.29 5.29 0 00-.16-.76c-.356-1.158-1.372-1.737-2.53-1.63-1.384.268-2.395 1.497-2.617 2.845-.249 1.508.88 3.089 2.43 3.187.549.035.664.051 1.179-.094.879-.403 1.549-1.116 1.735-2.101.104-.554-.017-1.316-.037-1.447z"></path>
               <path
                  fill="#fff"
                  d="M41.346 59.255c.333.138.74.014 1.046-.14.708-.357.977-1.563.376-2.124-.593-.554-1.746-.069-1.979.655-.17.524.042.878.312 1.311 0 0 .145.257.245.298z"
               ></path>
               <path
                  fill="#fff"
                  d="M26.875 55.365c.311.297.679.581 1.143.476.189-.042.347-.166.491-.287.472-.397 1.014-1.144.608-1.771a1.049 1.049 0 00-.389-.329c-.213-.104-.534-.217-.776-.161-.459.105-.797.484-1.027.875-.116.198-.222.44-.197.675.021.203.147.522.147.522z"
               ></path>
               <path d="M4.126 75.955l-.328.148c-.647.348-1.243.895-1.724 1.44-.398.45-.764.955-1.058 1.48-.19.339-.337.59-.46.939-.336.954.371 1.546 1.259 1.725 1.372.277 2.871.132 4.255-.05.666-.088 1.976-.35 2.072-1.176.094-.811-1.089-1.759-1.521-2.308-.509-.645-.967-1.326-1.573-1.889l-.087-.083a4.3 4.3 0 00-.26-.191 8.256 8.256 0 00-.575-.035z"></path>
               <path
                  fill="#fff"
                  d="M3.461 77.229s-.272.294-.555.543c-.488.428-.955.871-1.239 1.464-.136.283-.305.776-.168 1.09.138.315.517.242.711.024.347-.391.428-.964.754-1.38.369-.471.988-.751 1.258-1.297l.038-.115c.082-.299-.121-.573-.435-.533-.136.018-.264.12-.364.204z"
               ></path>
            </g>
         </g>
      </>
   );
};

export const SvgWindow = ({
   width = 200,
   height = 320,
   name,
   renard: show,
}) => {
   const { clip, d, renard } = windows.find(w => w.n === name);

   const id = `${name}-clip`, activeId = `${name}-active`;

   return (
      <svg
         viewBox="0 0 200 320"
         {...{ width, height }}
         xmlns="http://www.w3.org/2000/svg"
         style={{
            fillRule: "evenodd",
            clipRule: "evenodd",
            strokeLinecap: "square",
            strokeMiterlimit: "1.5",
         }}
      >
         <defs>
            <linearGradient id={activeId} x1="0" y1="0" x2="0" y2="1">
               <stop offset="0%" stopColor="#006BB9" />
               <stop offset="100%" stopColor="#013D8D" />
            </linearGradient>
         </defs>
         {show && <>
         <path d={clip} fill={`url(#${activeId})`} />
         <Renard {...{ clip, id }} {...renard} />
         </>}

         <g strokeWidth="3px" stroke="black" fill="none">
            {d.map((str, i) => (
               <path d={str} key={i} />
            ))}
            <path d={clip} />
         </g>

      </svg>
   );
};

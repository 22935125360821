import { mo } from "../shed";

const styles = {
   global: ({ colorMode }) => {
      const m = mo({
         body: {
            bg: "dark.bgSpecial",
            color: "dark.fgSpecial",
         },
         a: {
            color: "blue",
            textDecoration: "underline",
            z: null,
            y: -8,
         },
         x: null,
      }, key => key[0]==="." ? colorMode + key : key);
      // console.log(m);
      return m;
   },
};

export default styles;

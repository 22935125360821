
const colors = {
   light: {
      inverse: "#F2F4FC",
      straight: "#25201F",
   },
   dark: {
      inverse: "#25201F",
      straight: "#AEB0BB",
      bgSpecial: "#323546",
      fgSpecial: "#000000",
   }
};

export default colors;

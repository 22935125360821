import "./ani.css";

const themes = {
   light: {
      colors: {
         straight: "#25201F",
      },
   },
};
const currentScheme = "light";
const theme = themes[currentScheme];

/* const ani = {
   c1_t: keyframes`
      0% { transform: translate(14px,14px) rotate(-180deg); }
      100% { transform: translate(14px,14px) rotate(180deg); } }
   `,

   "c1-2_t": keyframes`
      0% { transform: translate(14px,14px) rotate(-180deg); }
      60% { transform: translate(14px,14px) rotate(-87.43deg); }
      100% { transform: translate(14px,14px) rotate(-87.43deg); }
   `,

   "c1-2_v": keyframes`
      0% { visibility: visible; animation-timing-function: steps(1); }
      60% { visibility: hidden; animation-timing-function: steps(1); }
      100% { visibility: hidden; animation-timing-function: steps(1); }
   `,

   "c1-2_da": keyframes`
      0% { stroke-dasharray: 7.49px 67.44px; } 6.65% { stroke-dasharray: 7.49px 67.44px; } 55% { stroke-dasharray: 0.75px 74.18px; } 100% { stroke-dasharray: 0.75px 74.18px; }
   `,

   "c1-2_do": keyframes` 0% { stroke-dashoffset: 0px; } 6.65% { stroke-dashoffset: 0px; } 55% { stroke-dashoffset: -4px; } 100% { stroke-dashoffset: -4px; } `,
   "c1-3_t": keyframes` 0% { transform: translate(14px,14px) rotate(-96deg); } 23.3% { transform: translate(14px,14px) rotate(-96deg); } 83.3% { transform: translate(14px,14px) rotate(-40deg); } 100% { transform: translate(14px,14px) rotate(-40deg); } `,
   "c1-3_v": keyframes` 0% { visibility: hidden; animation-timing-function: steps(1); } 23.3% { visibility: visible; animation-timing-function: steps(1); } 83.3% { visibility: hidden; animation-timing-function: steps(1); } 100% { visibility: hidden; animation-timing-function: steps(1); } `,
   "c1-3_da": keyframes` 0% { stroke-dasharray: 7.49px 67.44px; } 36.65% { stroke-dasharray: 7.49px 67.44px; } 80% { stroke-dasharray: 0.75px 74.18px; } 100% { stroke-dasharray: 0.75px 74.18px; } `,
   "c1-3_do": keyframes` 0% { stroke-dashoffset: 0px; } 36.65% { stroke-dashoffset: 0px; } 80% { stroke-dashoffset: -4px; } 100% { stroke-dashoffset: -4px; } `,
   "c1-4_t": keyframes` 0% { transform: translate(14px,14px) rotate(-36deg); } 40% { transform: translate(14px,14px) rotate(-36deg); } 100% { transform: translate(14px,14px) rotate(-1.78deg); } `,
   "c1-4_v": keyframes` 0% { visibility: hidden; animation-timing-function: steps(1); } 40% { visibility: visible; animation-timing-function: steps(1); } 100% { visibility: hidden; animation-timing-function: steps(1); } `,
   "c1-4_da": keyframes` 0% { stroke-dasharray: 7.49px 67.44px; } 43.3% { stroke-dasharray: 7.49px 67.44px; } 96.65% { stroke-dasharray: 0.75px 74.18px; } 100% { stroke-dasharray: 0.75px 74.18px; } `,
   "c1-4_do": keyframes` 0% { stroke-dashoffset: 0px; } 43.3% { stroke-dashoffset: 0px; } 96.65% { stroke-dashoffset: -4px; } 100% { stroke-dashoffset: -4px; } `,
}; */

/* console.log(
   css`${ani["c1_t"]} 2s linear infinite both`
); */

export const icons = {
   apply: (
      <>
         <circle cx="33" cy="14" r="12"></circle>
         <path
            stroke="#25201F"
            strokeMiterlimit="1.5"
            strokeWidth="1.5"
            d="M10.111 14.75h10.907C21.364 21.063 26.601 26 32.999 26 39.623 26 45 20.623 45 14S39.623 2 33 2c-5.228 0-9.679 3.35-11.323 8.018-.703-.03-1.596-.802-2.115-2.133-1.06-2.73-2.438-5.135-5.4-5.135H2.112"
         ></path>
         <path
            stroke="#25201F"
            strokeMiterlimit="2"
            strokeWidth="1.5"
            d="M4 6.781h9.444M7 10.719h9.444"
         ></path>
      </>
   ),
   spinner: (
      <>
         <g
            stroke="#25201F"
            fill="none"
            strokeMiterlimit="1"
            strokeLinejoin="bevel"
            strokeWidth="1.5"
            transform="translate(19)"
         >
            <ellipse
               id="c1"
               rx="12"
               ry="12"
               strokeDasharray="18.73 56.2"
               strokeDashoffset="0"
               strokeLinecap="butt"
               transform="translate(14,14) rotate(-180)"
               style={{ animation: "2s linear infinite both c1_t" }}
            />
            <ellipse
               id="c1-2"
               rx="12"
               ry="12"
               strokeDasharray="7.49 67.44"
               strokeDashoffset="0"
               strokeLinecap="butt"
               visibility="visible"
               transform="translate(14,14) rotate(-180)"
               style={{
                  animation:
                     "2s linear infinite both c1-2_t, 2s linear infinite forwards c1-2_v, 2s linear infinite both c1-2_da, 2s linear infinite both c1-2_do",
               }}
            />
            <ellipse
               id="c1-3"
               rx="12"
               ry="12"
               strokeDasharray="7.49 67.44"
               strokeDashoffset="0"
               strokeLinecap="butt"
               visibility="hidden"
               transform="translate(14,14) rotate(-96)"
               style={{
                  animation:
                     "2s linear infinite both c1-3_t, 2s linear infinite forwards c1-3_v, 2s linear infinite both c1-3_da, 2s linear infinite both c1-3_do",
               }}
            />
            <ellipse
               id="c1-4"
               rx="12"
               ry="12"
               strokeDasharray="7.49 67.44"
               strokeDashoffset="0"
               strokeLinecap="butt"
               visibility="hidden"
               transform="translate(14,14) rotate(-36)"
               style={{
                  animation:
                     "2s linear infinite both c1-4_t, 2s linear infinite forwards c1-4_v, 2s linear infinite both c1-4_da, 2s linear infinite both c1-4_do",
               }}
            />
         </g>
      </>
   ),
   hourglass: (
      <>
         <path
            stroke="#25201F"
            strokeWidth="1.5"
            d="M33 26c6.627 0 12-5.373 12-12S39.627 2 33 2 21 7.373 21 14s5.373 12 12 12z"
         ></path>
         <path
            stroke="#25201F"
            strokeMiterlimit="2"
            strokeWidth="1.5"
            d="M29.757 8.118V9.98c0 2.325 2.24 2.09 2.24 4.019 0 1.93-2.24 1.694-2.24 4.019v1.863M36.75 8.118V9.98c0 2.325-2.24 2.09-2.24 4.019 0 1.93 2.24 1.694 2.24 4.019v1.863"
         ></path>
         <path
            stroke="#25201F"
            strokeMiterlimit="2"
            strokeWidth="1.5"
            d="M27.619 8.438S27.497 6.47 29.67 6.47h7.162c2.174 0 2.052 1.967 2.052 1.967M27.619 19.562s-.122 1.967 2.052 1.967h7.162c2.174 0 2.052-1.967 2.052-1.967"
         ></path>
      </>
   ),
   chat: (
      <>
         <path
            fill="#25201F"
            fillRule="evenodd"
            d="M23.25 21.35s-.18.352-1.656 1.735c-1.477 1.382-2.72 2.203-2.72 2.203s.584 1.122 3.081 1.287c2.498.166 4.442-1.942 4.442-1.942L23.25 21.35z"
            clipRule="evenodd"
         ></path>
         <circle
            cx="33"
            cy="14"
            r="12"
            stroke="#25201F"
            strokeWidth="1.5"
         ></circle>
      </>
   ),
};

import { Box, Button, Flex, Avatar } from "@chakra-ui/react";
import { cl } from "../shed";

import "./button.css";
import { icons } from "./icons";

const themes = {
   light: {
      colors: {
         straight: "#19223A",
         inverse: "#F0F4FB",
      },
   },
};

const Icon = ({
   size = 24,
   width,
   height,
   strokeWidth = 1.5,
   theme = "light",
}) => {
   const {
      colors: { straight, inverse },
   } = themes[theme];

   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width={width || size}
         height={height || size}
         viewBox="0 0 24 24"
         fill="none"
      >
         <path
            stroke={straight}
            strokeWidth={strokeWidth}
            d="M9 3.75H6a3 3 0 00-3 3V19.5a3 3 0 003 3h12a3 3 0 003-3v-4.031"
         ></path>
         <path
            stroke={straight}
            strokeWidth={strokeWidth}
            d="M22.5 7.125c0-3.96-3.117-5.953-5.953-5.953-2.836 0-6.047 1.969-6.047 5.953s3.844 7.357 6.047 9.375c2.156-2.016 5.953-5.414 5.953-9.375z"
         ></path>
         <circle cx="16.5" cy="7.148" r="1.266" fill={straight}></circle>
      </svg>
   );
};

const ApplyButton = ({ icon, children }) => {
   return (
      <Button className={icon ? null : "ani"}>
         {icon && (
            <>
               <img
                  src="/assets/spinner01.svg"
                  width="28"
                  height="28"
                  alt="loading..."
               />
               &nbsp;&nbsp;
            </>
         )}
         {children}
      </Button>
   );
};

const SVGIcon = (props) => {
   const name = cl(props) || "apply";
   // console.log(name);
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         shapeRendering="geometricPrecision"
         textRendering="geometricPrecision"
         width="47"
         height="28"
         viewBox="0 0 47 28"
         fill="none"
         style={{ whiteSpace: "pre" }}
      >
         {icons[name]}
      </svg>
   );
};

const TheIcon = ({ name }) => {

   const iconKeys = {
      spinner: ["wait"],
      apply: ["apply"],
      hourglass: ["applied"],
      chat: ["chat"]
   }

   return (
      <Box w="47px" h="28px" pos="relative">
         {name === "apply" && (
            <Box pos="absolute" left="21px" top="2px">
               <Avatar src="/assets/img/avatars/hero02.png" size="xs" />
            </Box>
         )}
         {name === "chat" && (
            <Box pos="absolute" left="21px" top="2px">
               <Avatar src="/assets/img/avatars/hero04.png" size="xs" />
            </Box>
         )}
         <Box pos="absolute">
            <SVGIcon {...Object.keys(iconKeys).reduce((obj, key) => (iconKeys[key].includes(name) ? {...obj, [key]: true } : obj), {}) } />
         </Box>
      </Box>
   );
};

const labels = {
   en: {
      apply: "Apply",
      wait: "Applying...",
      applied: "Waiting for confirmation",
      chat: "Need to talk"
   },
   ru: {
      apply: "Взяться",
      wait: "Отправляем...",
      applied: "Ждите ответа",
      chat: "Надо поговорить"
   },
};
const TheLabel = ({ lang, name }) => {
   return <Box>{labels[lang][name]}</Box>;
};
 
const TheButton = ({ lang="en", ...props}) => {
   const name = cl(props);
   return (
      <Button>
         <TheIcon {...{ name }} />
         <TheLabel {...{ lang, name }} />
      </Button>
   );
};

const Shed = () => {
   return (
      <Box bg={themes.light.colors.inverse} p="40">
         1.5px border
         <Box __css={{ border: "1.5px solid black" }} p="20" borderRadius="12">
            <Icon size={32} />
            24px 1.5px stroke icon sized to 32px
            <Icon size={24} />
            <Box p="10" my="20" bgColor="white" display="flex" gap="4">
               <ApplyButton>Animated bgcolor</ApplyButton>

               <ApplyButton icon>Spinner</ApplyButton>
            </Box>
            <Flex>
               <TheButton apply />
               <TheButton wait />
               <TheButton applied />
               <TheButton chat />
            </Flex>
         </Box>
      </Box>
   );
};

export default Shed;

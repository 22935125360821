import "@fontsource/manrope/600.css";
import "@fontsource/manrope/300.css";
import "@fontsource/manrope/400.css";
import {
   ChakraProvider,
   Box,
   // Text, Link, VStack, Code, Grid,
   // theme,
   Flex,
   Image,
} from "@chakra-ui/react";
import theme from "./theme";
import { Route, Routes } from "react-router-dom";
import Shed from "./ui/shed";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
import Icon from "./Icon";
import { SvgWindow } from "./svg";
import { getRandomInt, shuffleArray } from "./shed";
import { useRef } from "react";
// import { Logo } from "./Logo";

// console.log(theme);

// function App0() {
//    return (
//       <ChakraProvider theme={theme}>
//          <Box textAlign="center" fontSize="xl">
//             <Grid minH="100vh" p={3}>
//                <ColorModeSwitcher justifySelf="flex-end" />
//                <VStack spacing={8}>
//                   <Logo h="40vmin" pointerEvents="none" />
//                   <Text>
//                      Edit <Code fontSize="xl">src/App.js</Code> and save to
//                      reload.
//                   </Text>
//                   <Link
//                      color="teal.500"
//                      href="https://chakra-ui.com"
//                      fontSize="2xl"
//                      target="_blank"
//                      rel="noopener noreferrer"
//                   >
//                      Learn Chakra
//                   </Link>
//                </VStack>
//             </Grid>
//          </Box>
//       </ChakraProvider>
//    );
// }

const HomeScreen = () => {
   const { current: r } = useRef(getRandomInt(1, 9));
   return (
      <Flex
         w="100vw"
         minH="100vh"
         alignItems="center"
         justifyContent="center"
         p="40px"
         flexDir="column"
      >
         <Flex
            justifyContent="center"
            flexWrap="wrap"
            gap={["2px", "15px", "20px", null, "30px"]}
            maxW={["340px", null, "490px", null, "510px"]}
            minW="340px"
         >
            {shuffleArray([1, 2, 3, 4, 5, 6, 7, 8, 9]).map((n, i) => (
               <Box w={["100px", null, "150px"]} key={i}>
                  <SvgWindow
                     name={`window-${n}`}
                     width="100%"
                     height="100%"
                     renard={r === n}
                  />
               </Box>
            ))}
         </Flex>
         <Box my="10px" color="#000" letterSpacing="3px" fontSize="22px" fontWeight={["400", null, "600"]}>
            t.w.i.m.c.
         </Box>
         {/* <ColorModeSwitcher justifySelf="flex-end" /> */}
      </Flex>
   );
};

const App = () => {
   return (
      <ChakraProvider theme={theme}>
         <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/ui/shed" element={<Shed />} />
         </Routes>
      </ChakraProvider>
   );
};

export default App;


import { extendTheme } from "@chakra-ui/react";
import colors from "./colors";
import styles from "./styles";

const config = {
   useSystemColorMode: true,
};

const fonts = {
   heading: "Manrope, sans-serif",
   body: "Manrope, sans-serif",
}

const overrides = {
   fonts,
   config,
   colors,
   styles,
};

export default extendTheme(overrides);

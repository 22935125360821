import { useColorModeValue, useToken } from "@chakra-ui/react";

function Icon() {

   const [lightStraight, darkStraight] = useToken("colors", ["light.straight", "dark.straight"]);

   const straight = useColorModeValue(lightStraight, darkStraight);

   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="256"
         height="256"
         fill="none"
         viewBox="0 0 256 256"
      >
         <mask
            id="mask0_524_3920"
            style={{ maskType: "alpha" }}
            width="256"
            height="256"
            x="0"
            y="0"
            maskUnits="userSpaceOnUse"
         >
            <circle cx="128" cy="128" r="128" fill="#D9D9D9"></circle>
         </mask>
         <g mask="url(#mask0_524_3920)">
            <path
               fill="#F9642D"
               d="M173.573 271.185c-.151-6.83.011-42.109-3.863-62.638-2.46-13.044-3.793-26.241-6.297-39.279-1.992-10.366-5.35-21.166-4.847-31.829.282-5.978 1.847-11.745 3.615-17.426 2.993-9.613 5.617-19.466 9.493-28.776 3.133-7.529 7.29-14.533 10.842-21.858 1.776-3.66 3.589-7.48 4.325-11.517.956-5.248-2.163-6.002-5.622-2.775a93.436 93.436 0 00-4.981 5.044c-9.247 11.22-17.303 23.435-23.928 36.373-3.235 6.318-5.447 14.41-9.882 20.047-2.013 2.559-10.881 2.046-13.535 2.139-1.072.037-2.185-.235-2.929-1.045-3.023-3.282-4.76-11.077-6.136-14.656-2.83-7.74-5.516-15.541-8.602-23.182-1.786-4.426-3.592-8.858-5.624-13.179-.985-2.09-1.967-4.185-3.059-6.225-1.4-2.615-2.79-5.186-4.847-7.331-1.117-1.162-3.678-2.01-4.28.247-.257.964-.471 1.953-.484 2.949a46.193 46.193 0 00.368 6.447c.337 2.621.923 5.205 1.463 7.789 2.84 13.593 6.755 26.816 8.955 40.564 1.217 7.603 2.69 16.721-.244 24.102-2.624 6.602-8.866 11.319-14.11 15.745-9.594 8.092-19.059 16.301-29.214 23.694-1.435 1.045-2.878 2.084-4.313 3.128-3.508 2.553-7.193 5.063-10.19 8.24-.9.952-2.807 2.838-2.33 4.426.38 1.268 2.705 1.465 3.72 1.515 4.517.216 9.243-1.36 13.5-2.646 11.936-3.591 24.036-6.756 35.928-10.484 3.725-1.168 11.295-3.969 14.997-1.131 3.064 2.349.841 9.223.56 12.289-2.569 28.009-9.402 67.77-11.251 80.974 10.294.976 28.145.123 38.503-.124 11.44-.272 22.86.933 34.299.389z"
            ></path>
            <path
               fill="#000"
               d="M118.436 158.438c2.165-1.428 3.424-3.498 2.946-6.132a11.524 11.524 0 00-.625-2.219l-.054-.142c-.311-.618-.247-.649-.699-1.125-.412-.439-.752-.767-1.228-1.101-1.834-1.285-3.963-.37-5.565.81-.335.247-.724.557-.99.909-1.696 2.244-1.537 5.675.455 7.727.641.655 1.506 1.156 2.403 1.348l.479.061.293.031.572-.006c.595-.012.58-.062 1.151-.29l.862.129zM95.54 146.143a9.158 9.158 0 00-.278-1.323c-.62-2.015-2.387-3.023-4.403-2.837-2.407.47-4.167 2.609-4.553 4.951-.432 2.621 1.53 5.372 4.229 5.545.954.062 1.154.093 2.05-.16 1.53-.705 2.697-1.948 3.02-3.66.182-.964-.03-2.287-.064-2.516z"
            ></path>
            <path
               fill="#fff"
               d="M113.615 153.388c.581.235 1.288.019 1.821-.247 1.232-.618 1.7-2.72.654-3.697-1.032-.964-3.039-.117-3.444 1.138-.294.914.073 1.526.543 2.287 0 0 .254.445.426.519zM88.435 146.613c.541.519 1.183 1.013 1.99.834.33-.074.604-.29.854-.501.822-.692 1.764-1.996 1.059-3.084-.148-.229-.437-.458-.678-.575-.37-.179-.93-.371-1.35-.278-.8.185-1.387.847-1.787 1.527-.202.34-.386.76-.343 1.174.037.352.255.903.255.903z"
            ></path>
            <path
               fill="#000"
               d="M48.853 182.441l-.57.26c-1.128.606-2.165 1.558-3 2.504a15.044 15.044 0 00-1.842 2.577c-.33.588-.587 1.027-.8 1.632-.584 1.663.646 2.689 2.19 3.005 2.387.476 4.996.228 7.404-.087 1.16-.154 3.438-.612 3.605-2.052.163-1.41-1.894-3.06-2.647-4.012-.884-1.125-1.681-2.306-2.736-3.289l-.151-.142a6.701 6.701 0 00-.453-.334c-.328-.031-.661-.049-1-.062z"
            ></path>
            <path
               fill="#fff"
               d="M47.696 184.661s-.475.507-.967.946c-.847.742-1.66 1.514-2.156 2.547-.237.488-.53 1.347-.291 1.897.24.544.9.421 1.237.037.604-.679.744-1.675 1.312-2.398.642-.822 1.719-1.311 2.188-2.256l.066-.204c.144-.52-.21-.996-.757-.928-.235.031-.458.211-.632.359z"
            ></path>
            <circle
               cx="128"
               cy="128"
               r="127.25"
               stroke={straight}
               strokeWidth="1.5"
            ></circle>
         </g>
      </svg>
   );
}

export default Icon;

export function objType(obj) {
   return /^\[object (\w+)]$/
      .exec(Object.prototype.toString.call(obj))[1]
      .toLowerCase();
}

export function cl() {
   const classList = Array.prototype.slice
      .call(arguments)
      .filter(c => c)
      .map(c => {
         if (objType(c) === "object") {
            return Object.keys(c)
               .map(key => (c[key] === true ? key : false))
               .filter(c => c);
         }
         return c;
      })
      .flat(Infinity);

   // console.log(classList);

   return classList.length > 0 ? classList.filter(c => c).join(" ") : null;
}

export const filterObject = (obj, condition) => {
   if (Array.isArray(obj)) {
      const res = obj
         .map(e => filterObject(e, condition))
         .filter(e => e !== false);
      return res.length > 0 ? res : false;
   }

   if (objType(obj) === "object") {
      let res = {};

      const fields = Object.entries(obj).reduce(
         (acc, [key, val]) =>
            !["object", "array"].includes(objType(val))
               ? { ...acc, [key]: val }
               : acc,
         {}
      );

      const arrays = Object.entries(obj).reduce(
         (acc, [key, val]) =>
            objType(val) === "array" && filterObject(val, condition)
               ? { ...acc, [key]: filterObject(val, condition) }
               : acc,
         {}
      );

      const objects = Object.entries(obj).reduce(
         (acc, [key, val]) =>
            objType(val) === "object" && filterObject(val, condition)
               ? { ...acc, [key]: filterObject(val, condition) }
               : acc,
         {}
      );

      if (
         Object.keys(arrays).length > 0 ||
         Object.keys(objects).length > 0 ||
         condition(obj)
      ) {
         res = { ...fields, ...arrays, ...objects };
      }

      return Object.keys(res).length > 0 && res;
   }

   if (condition(obj)) return obj;

   return false;
};

export const mapObj = (obj, cond, mutator) => {
   if (Array.isArray(obj)) {
      return obj.map(e => mapObj(e, cond, mutator));
   }

   if (objType(obj) === "object") {
      const updated = Object.keys(obj).reduce(
         (acc, key) => ({ ...acc, [key]: mapObj(obj[key], cond, mutator) }),
         {}
      );
      try {
         return cond(updated) ? mutator(updated) : updated;
      } catch (err) {
         return updated;
      }
   }

   try {
      return cond(obj) ? mutator(obj) : obj;
   } catch (err) {
      return obj;
   }
};

export const mo = (obj, mutator) => {
   if (Array.isArray(obj)) {
      return obj.map(e => mo(e, mutator));
   }

   if (objType(obj) === "object") {
      const updated = Object.keys(obj).reduce(
         (acc, key) => ({ ...acc, [key]: mo(obj[key], mutator) }),
         {}
      );
      try {
         return mutator(updated);
      } catch (err) {
         return updated;
      }
   }

   try {
      return mutator(obj);
   } catch (err) {
      return obj;
   }
};


export function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function shuffleArray(array) {
    const newArray = [...array];
    // https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
    for (let i = newArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
}
